import { DateTime } from "luxon";
import { useContext, useEffect, useState } from "react";
import { Form, Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { getDistribution, getDistributionTransactions, processAirdropDistribution, startAirdropDistribution } from "../../api/distributions.service";
import { createCoinMintOrder, getBurnedTokens, getMintTokens } from "../../api/mints.service";
import { AuthorizationContext } from "../../contexts/auth.context";
import { DistributionStatus, DistributionType, MintStatus, MintTokenFilter, MintTokenFilterType } from "../../utils/constants";
import { LoadingBars } from "../LoadingBars";

const BLOCKED_WALLETS = [
  // `EyEz67X6PENcC9uj2zEZGmJ6TnkpnAwfeu8xH5a4zsFg`,
  // `DCersMnDMAbA1PQetMhbTztQ2NJiWxxzrgcmiakhQTiW`,
  // `G9LoCmXhHxHFkP9v9EyB8uwzyARF68aKm2CC9V268vv5`,
  // `FAo2Nitp2pCX4CJSKkA7tUYKD4DexHkcpNcSAPv2Pbb1`,
  // `3GBn8PiTZAMSjqymyZQ4KvvLgiScubZmZYEkevCEzv5h`,
  // `3jQZfbGWkZyRnsUv6ULT6q6MVj5qaCQCsAyLT9Byiztm`
];

const S3_BASE = 'assets/';
export const openInNewTab = (url) => {
  const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
  // console.log(newWindow);
  if (newWindow) newWindow.opener = null
};

const getBadgeColorByStatus = (status) => {
  if (status === DistributionStatus.CANCELED) {
    return `bg-warning`;
  }
  if (status === DistributionStatus.STARTED) {
    return `bg-primary`;
  }
  if (status === DistributionStatus.PROCESSING) {
    return `bg-danger`;
  }
  if (status === DistributionStatus.FINALIZED) {
    return `bg-success`;
  }
}

const TransactionCard = ({
  walletId,
  transaction,
  number
}) => {
  const date = DateTime.fromISO(transaction?.createdAt);
  return (
    <div className="card d-sm-flex flex-sm-row align-items-sm-center justify-content-between border-0 shadow-sm p-3 p-md-4 mb-4">
      <div className="d-flex align-items-center pe-sm-3">
        <div className="">
          <h6 className="mb-1">Transaction #{number}</h6>
          <div className="d-flex">
            <i className="bx bxs-user-circle fs-xl opacity-60 me-2" />
            <h6 className="mb-1">{walletId}</h6>
          </div>
          <div className="d-flex">
            <i className='bx bxs-calendar-alt fs-xl opacity-60 me-2' ></i>
            <h6 className="mb-1 fs-sm">{date.toLocaleString(DateTime.DATETIME_FULL)}</h6>
          </div>
          <div className="d-flex">
            <i className='bx bxs-bolt fs-xl opacity-60 me-2' ></i>
            <h6 className="mb-2"><span className={"badge bg-warning"}></span><span className={"badge " + getBadgeColorByStatus(transaction?.status)}>{transaction?.status?.split('_').join(' ')}</span></h6>
          </div>
          {
            transaction?.instructions?.map((ix) => (
              <>
                <div className="d-flex">
                  <i className="bx fs-xl opacity-60 me-2 bxs-coin cursor-pointer" onClick={() => openInNewTab(`https://solscan.io/token/${ix?.tokenId}`)}></i>
                  <a href="javascript:;" onClick={() => openInNewTab(`https://solscan.io/token/${ix?.tokenId}`)}>{ix?.tokenId}
                  </a>
                </div>
              </>))
          }
          <div className="d-flex">
            <i className='bx bxs-link-alt fs-xl opacity-60 me-2' ></i>
            {
              !!transaction?.signature?.length &&
              <>
                <a href="javascript:;" onClick={() => openInNewTab(`https://solscan.io/tx/${transaction?.signature}`)}>{transaction?.signature}</a>
              </>
            }
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-end pt-3 pt-sm-0">
        {/* <button type="button" className="btn btn-outline-secondary px-3 px-xl-4 me-3">
                  <i className="bx bx-collection fs-xl me-lg-1 me-xl-2" />
                  <span className="d-none d-lg-inline">SEE WALLET'S ORDERS</span>
              </button> */}
      </div>
    </div>
  )
}


export function NewFractionalizationModal({ wallet, styleCss, ...props }) {
  const navigate = useNavigate();
  const { auth, signNonce } = useContext(AuthorizationContext);
  const [totalSol, setTotalSol] = useState();
  const [loading, setLoading] = useState(false);
  const [loadingTxes, setLoadingTxes] = useState(true);
  const [loadingButton, setLoadingButton] = useState(false);
  const [tokens, setTokens] = useState();

  const [distributionDetails, setDistributionDetails] = useState();
  const [distributionTransactions, setDistributionTransactions] = useState();

  const [coins, setCoins] = useState();
  useEffect(() => {
    init();
  }, [])

  const init = async () => {
    setLoading(true);
    const coins = await getBurnedTokens();
    const result = coins?.filter(c => !BLOCKED_WALLETS.includes(c.walletId))?.map(coin => {
      const fileName = coin?.uri?.split(S3_BASE)[1];
      const [tokenIndex] = fileName?.split('.json');
      return {
        ...coin,
        tokenNum: (+tokenIndex) + 1,
        tokenIndex,
        name: `DCF #${(+tokenIndex) + 1}`
      }
    })
      .sort((a, b) => a.tokenNum - b.tokenNum)
      .filter(coin => !coin?.fractionalized?.length);
    setCoins(result);
    setLoading(false);
  }


  const [selected, setSelected] = useState();
  const onSelectChange = (ev) => {
    console.log(ev.target.value);
    setSelected(ev.target.value);
  }

  const doCoinSplit = async (selected) => {
    setLoadingButton(true);
    try {
      console.log('splitting')
      console.log(selected);
      const res = await createCoinMintOrder(selected, auth?.idToken);
      props.onClose();
      console.log(res);
    } catch (e) {
      setLoadingButton(false);
      console.log(e);
    }
    setLoadingButton(false);
  }

  const doMultiCoinSplit = async (items) => {
    setLoadingButton(true);
    console.log(items);
    try {
      for (var i = 0; i < items?.length; i++) {
        const coin = items[i];
        const tokenId = coin?.tokenId;
        console.log(tokenId);
        const res = await createCoinMintOrder(tokenId, auth?.idToken);
        console.log(res);
      }
      props.onClose();

    } catch (e) {
      console.log(e);
    }
    setLoadingButton(false);

  }

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      contentClassName={'light' + `-color`}
      centered
    >
      <Modal.Body className={"p-0"}>
        <div className="card card-user shadow-lg">

          <div className={"card-body p-4" + (styleCss === 'dark' ? ' pb-0' : '')} style={{ maxHeight: '690px', overflow: 'scroll' }}>
            <div className="w-100 align-self-end" style={{ maxWidth: '526px' }}>
              <h4 className="text-center text-xl-start mb-0">Split a Coin to 27 Cats</h4>
              {
                wallet?.connected &&
                <>
                  {
                    !loading &&
                    <div className="position-relative mt-3">   {
                      coins?.map(coin => (<>
                        <h6>
                          {coin.name} to Wallet ({coin?.walletId?.slice(0, 4)})
                        </h6>
                      </>))
                    }
                      <Form.Select onChange={onSelectChange} aria-label="Default select example">
                        <option>Select a Coin</option>
                        {
                          coins?.map(coin => (<>
                            <option value={coin?.tokenId}>
                              {coin.name} to Wallet ({coin?.walletId?.slice(0, 4)})
                            </option>
                          </>))
                        }
                      </Form.Select>
                    </div>
                  }
                  {
                    loading &&
                    <div className="mb-3 mt-5">
                      <LoadingBars />
                    </div>
                  }
                </>
              }
            </div>
          </div>
          <div className="card-footer">
            {
              !loading &&
              <>
                {/* <button type="button" disabled={loadingButton} onClick={() => doMultiCoinSplit(coins?.slice(0, coins?.length > 10 ? 10 : coins?.length))} className="btn btn-warning shadow-warning btn-lg w-100"> */}
                  <button type="button" disabled={!selected || loadingButton} onClick={() => doCoinSplit(selected)} className="btn btn-warning shadow-warning btn-lg w-100">
                  {
                    !loadingButton &&
                    <>
                      <i className="bx bx-coin fs-xl me-lg-1 me-xl-2" />
                      <span className="d-none d-lg-inline">DO A MULTI COIN SPLIT</span>
                    </>
                  }
                  {
                    loadingButton &&
                    <>
                      <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                      <span className="visually-hidden">Loading...</span>
                    </>
                  }
                </button>
              </>
            }
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

import { Dropdown, OverlayTrigger, Popover } from "react-bootstrap";
import DropdownItem from "react-bootstrap/esm/DropdownItem";
import { Link } from "react-router-dom";

const MenuBar = () => {
  const CoinFlipsButton = () => {
    return (
      <>
        <Link to={`/orders`} className="text-decoration-none">
          <button className="btn btn-opacity btn-sm text-dark">
            Airdrops
          </button>
        </Link>
      </>
    );
  };

  const MasterPlanButton = () => {
    return (
      <>

        <Link to={`/master-plan`} className="ms-sm-2 ms-1 text-decoration-none">
          <button className="btn btn-opacity btn-sm text-dark">
            MASTER PLAN
          </button>
        </Link>
      </>
    );
  };

  const LanguageButton = () => {
    const CustomDropdownToggle = ({ onClick }: any) => {
      return <>
        <button className="btn btn-opacity btn-sm text-dark" onClick={onClick}>
          LANGUAGE
        </button>
      </>
    };
    return (
      <Dropdown align={{ sm: "end" }} className="ms-sm-2 ms-1">
        <Dropdown.Toggle as={CustomDropdownToggle} id="dropdown-basic">
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item>English</Dropdown.Item>
          <Dropdown.Item>Español</Dropdown.Item>
          <Dropdown.Item>Français</Dropdown.Item>
          <Dropdown.Item>日本語</Dropdown.Item>
          <Dropdown.Item>한국어</Dropdown.Item>
          <Dropdown.Item>简体中文</Dropdown.Item>
          <Dropdown.Item>繁體中文</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    );
  };

  const ButtonsRow = () => {
    return (
      <div className="d-flex flex-row mb-2 toolbar">
        <CoinFlipsButton />
        {/* <MasterPlanButton /> */}
        {/* <LanguageButton /> */}
      </div>
    );
  }

  return (
    <div className="social-icons" style={{ zIndex: 10 }}>
      <div className="d-flex flex-row flex-sm-column justify-content-start align-items-center">
        <div className="mt-3 d-flex flex-column shortcut-row" style={{ zIndex: 1 }}>
          <ButtonsRow />
        </div>
      </div>
    </div>
  );
};

export default MenuBar;

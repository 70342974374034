import { useWalletNfts } from "@nfteyez/sol-rayz-react";
import { useWallet } from "@solana/wallet-adapter-react";
import { WalletMultiButton } from "@solana/wallet-adapter-react-ui";
import { clusterApiUrl, Connection } from "@solana/web3.js";
import { DateTime } from "luxon";
import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { compile } from "sass";
import { startAirdropDistribution } from "../../api/distributions.service";
import { createCoinCatMintOrder, getBurnedTokens, getMints, getMintsByWallet, getMintTokens } from "../../api/mints.service";
import { getProfile } from "../../api/profiles.service";
import { LoadingBars } from "../../components/LoadingBars";
import { AirdropDistributionModal } from "../../components/modals/AirdropDistributionModal";
import { CoinCatModal } from "../../components/modals/CoinCatModalOpen";
import { InfoModal } from "../../components/modals/InfoModal";
import { MultiAirdropDistributionModal } from "../../components/modals/MultiAirdropDistributionModal";
import { NewAirdropModal } from "../../components/modals/NewAirdropModal";
import { NewFractionalizationModal } from "../../components/modals/NewFractionalizationModal";
import { NftCard } from "../../components/NftCard";
import { ToastCongrats } from "../../components/toasts/CongratsToast";
import { AudioContext } from "../../contexts/audio.context";
import { AuthorizationContext } from "../../contexts/auth.context";
import { StyleThemeContext } from "../../contexts/style-theme.context";
import { CHALLENGES, MintStatus, MintTokenFilter, MintTokenFilterType, WlTypes } from "../../utils/constants";
import { groupMintOrdersByWalletNotWaiting, groupMintOrdersByWalletWaiting } from "../../utils/helpers";

export const openInNewTab = (url: string) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
}

const capitalizeFirstLetter = (string: string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

const getBadgeColor = (wlType: any) => {
    if (wlType === WlTypes.COIN) {
        return `bg-warning`;
    }
    if (wlType === WlTypes.OG_ROLE) {
        return `bg-primary`;
    }
    if (wlType === WlTypes.PARTNER) {
        return `bg-light border`;
    }
    if (wlType === WlTypes.WL_KEY) {
        return `bg-success`;
    }
    if (wlType === WlTypes.FRACTIONALIZATION) {
        return `bg-dark`;
    }
}

const getBadgeColorByStatus = (status: any) => {
    if (status === MintStatus.WAITING) {
        return `bg-warning`;
    }
    if (status === MintStatus.STARTED) {
        return `bg-primary`;
    }
    if (status === MintStatus.PROCESSING) {
        return `bg-danger`;
    }
    if (status === MintStatus.COMPLETED) {
        return `bg-success`;
    }
}

const WalletSidebar = ({
    walletId,
    onSelect
}: any) => {
    const [profile, setProfile] = useState<any>();
    useEffect(() => {
        if (!walletId?.length || walletId !== profile?.walletId) {
            fetchProfile(walletId);
        }
    }, [walletId]);
    const fetchProfile = async (walletId: any) => {
        const profile = await getProfile(walletId);
        setProfile(profile);
    };
    return (
        <>
            <aside className="col-lg-3 col-md-4 border-end pb-5 mt-n5">
                <div className="position-sticky top-0">
                    <div className="text-center pt-5">
                        <div className="d-table position-relative mx-auto mt-2 mt-lg-4 pt-5 mb-3">
                            <img src={profile?.profileImageUrl ?? CHALLENGES[0].coinImageUrl} className="d-block rounded-circle" width={120} alt={walletId} />
                        </div>
                        <h2 className="h5 mb-1">{profile?.nickname ?? `Wallet (${walletId?.slice(0, 4)})`}</h2>
                        <button type="button" className="btn btn-secondary w-100 d-md-none mt-n2 mb-3" data-bs-toggle="collapse" data-bs-target="#account-menu">
                            <i className="bx bxs-user-detail fs-xl me-2" />
                            Menu
                            <i className="bx bx-chevron-down fs-lg ms-1" />
                        </button>
                        <div id="account-menu" className="list-group list-group-flush collapse d-md-block">
                            <a href="javascript:;" onClick={() => onSelect()} className={"list-group-item list-group-item-action d-flex align-items-center"}>
                                <i className="bx bx-arrow-back fs-xl opacity-60 me-2" />
                                See All Orders
                            </a>
                        </div>
                    </div>
                </div>
            </aside>
        </>
    )
}

const Sidebar = ({
    selected,
    onSelect
}: any) => {
    return (
        <>
            <aside className="col-lg-3 col-md-4 border-end pb-5 mt-n5">
                <div className="position-sticky top-0">
                    <div className="text-center pt-5">
                        <div className="d-table position-relative mx-auto mt-2 mt-lg-4 pt-5 mb-3">
                            <img src="https://i.imgur.com/UNHuEAc.png" className="d-block rounded-circle" width={120} alt="John Doe" />
                            {/* <button type="button" className="btn btn-icon btn-light bg-white btn-sm border rounded-circle shadow-sm position-absolute bottom-0 end-0 mt-4" data-bs-toggle="tooltip" data-bs-placement="bottom">
                                <i className="bx bx-refresh" />
                            </button> */}
                        </div>
                        <h2 className="h5 mb-1">Degen Full Send</h2>
                        <p className="mb-3 pb-3">Orders</p>
                        <button type="button" className="btn btn-secondary w-100 d-md-none mt-n2 mb-3" data-bs-toggle="collapse" data-bs-target="#account-menu">
                            <i className="bx bxs-user-detail fs-xl me-2" />
                            Menu
                            <i className="bx bx-chevron-down fs-lg ms-1" />
                        </button>
                        <div id="account-menu" className="list-group list-group-flush collapse d-md-block">
                            <a href="javascript:;" onClick={() => onSelect(MintStatus.WAITING)} className={"list-group-item list-group-item-action d-flex align-items-center " + (selected === MintStatus.WAITING ? "active" : "")}>
                                <i className="bx bx-bell fs-xl opacity-60 me-2" />
                                Waiting
                            </a>
                            <a href="javascript:;" onClick={() => onSelect(MintStatus.STARTED)} className={"list-group-item list-group-item-action d-flex align-items-center " + (selected === MintStatus.STARTED ? "active" : "")}>
                                <i className="bx bxs-hot fs-xl opacity-60 me-2" />
                                Started
                            </a>
                            <a href="javascript:;" onClick={() => onSelect(MintStatus.PROCESSING)} className={"list-group-item list-group-item-action d-flex align-items-center " + (selected === MintStatus.PROCESSING ? "active" : "")}>
                                <i className="bx bxs-bolt fs-xl opacity-60 me-2" />
                                Processing
                            </a>
                            <a href="javascript:;" onClick={() => onSelect(MintStatus.COMPLETED)} className={"list-group-item list-group-item-action d-flex align-items-center " + (selected === MintStatus.COMPLETED ? "active" : "")}>
                                <i className="bx bx-check-circle fs-xl opacity-60 me-2" />
                                Done
                            </a>
                        </div>
                    </div>
                </div>
            </aside>
        </>
    )
}

const ListSection = ({
    loading,
    buttonLoading,
    selectedStatus,
    orders,
    onSelectWallet,
    onSelectSend,
    onSelectDistro,
    onRefresh,
    onOpenFractionalization,
    onOpenMultiAirdrop,
    onMultiQueue,
    onMultiQueueBottom,
    onOpenCoinCat
}: any) => {
    const [sortedOrders, setSortedOrders] = useState<any>();
    const [sortType, setSortType] = useState<any>();
    return (
        <>
            <div className="col-md-8 offset-lg-1 pb-5 mb-2 mb-lg-4 pt-md-5 mt-n3 mt-md-0">
                <div className="ps-md-3 ps-lg-0 mt-md-2 pt-md-4 pb-md-2">
                    <h1 className="h2 pt-xl-1 mb-3 pb-2 pb-lg-3">{orders?.length} {selectedStatus?.length > 0 ? capitalizeFirstLetter(selectedStatus?.toLowerCase()) : ''} Orders</h1>
                    {/* <h2 className="h5 text-primary mb-4">Showing all orders</h2> */}
                    {/* <div className="row align-items-end pb-3 mb-2 mb-sm-4">
                        <div className="col-lg-6 col-sm-7">
                            <label htmlFor="notifications-email" className="form-label fs-base">Sort By</label>
                            <select id="notifications-email" className="form-select form-select-lg">
                                <option value="jonny@email.com">Wallet Name</option>
                                <option value="john_doe@email.com">WL Type</option>
                            </select>
                        </div>
                        <div className="col-lg-6 col-sm-5">
                            <button type="button" className="btn btn-link btn-lg px-0">
                                <i className="bx bx-plus fs-xl me-2" />
                                Add new email
                            </button>
                        </div>
                    </div> */}
                    <div className="d-flex justify-content-between mb-2">
                        {
                            !buttonLoading && !loading &&
                            <div className="py-2">
                                <button type="button" className="btn btn-outline-dark shadow ps-4" onClick={onRefresh}>
                                    <i className="bx bx-refresh fs-xl me-2" />
                                    REFRESH
                                </button>
                            </div>
                        }
                        {/* {
                            selectedStatus === MintStatus.WAITING && !buttonLoading && !loading &&
                            <div className="py-2">
                                <button type="button" className="btn btn-dark shadow ps-4" onClick={onMultiQueue}>
                                    <i className="bx bxs-meteor fs-xl me-2" />
                                    MULTI QUEUE
                                </button>
                            </div>
                        } */}
                        {
                            selectedStatus === MintStatus.WAITING &&
                            <div className="py-2">
                                <button type="button" className="btn btn-dark shadow ps-4" onClick={onOpenFractionalization}>
                                    <i className="bx bxs-coin-stack fs-xl me-2" />
                                    COIN SPLIT
                                </button>
                            </div>
                        }
                        {
                            selectedStatus === MintStatus.WAITING &&
                            <div className="py-2">
                                <button type="button" className="btn btn-dark shadow ps-4" onClick={onOpenCoinCat}>
                                    <i className="bx bxs-coin-stack fs-xl me-2" />
                                    COIN CAT
                                </button>
                            </div>
                        }
                        {
                            selectedStatus === MintStatus.STARTED &&
                            <div className="py-2">
                                <button type="button" className="btn btn-dark shadow ps-4" onClick={onOpenMultiAirdrop}>
                                    <i className="bx bxs-meteor fs-xl me-2" />
                                    MULTI AIRDROP
                                </button>
                            </div>
                        }
                    </div>
                    {
                        loading &&
                        <div className="my-5 py-5">
                            <LoadingBars />
                        </div>
                    }
                    {
                        !loading &&
                        <>
                            {
                                orders?.map((order: any, index: any) => {
                                    const date = DateTime.fromISO(order?.createdAt);
                                    return (
                                        <div key={index} className="card d-sm-flex flex-sm-row align-items-sm-center justify-content-between border-0 shadow-sm p-3 p-md-4 mb-4">
                                            <div className="d-flex align-items-center pe-sm-3">
                                                <div className="">
                                                    {/* <div className="d-flex">
                                                    <i className="bx bxs-user-circle fs-xl opacity-60 me-2" />
                                                    <h6 className="mb-1">Wallet ({order?.walletId?.slice(0, 4)}) ({order?.ids?.length})</h6>
                                                </div> */}
                                                    {
                                                        !!order?.nickname?.length &&
                                                        <div className="d-flex">
                                                            <i className="bx bxs-user-circle fs-xl opacity-60 me-2" />
                                                            <h6 className="mb-0">{order?.nickname}</h6>
                                                        </div>
                                                    }
                                                    <div className="d-flex">
                                                        <i className="bx bxs-wallet-alt fs-xl opacity-60 me-2" />
                                                        <h6 className="mb-0">{order?.walletId}</h6>
                                                    </div>
                                                    <div className="d-flex mb-1">
                                                        <i className='bx bxs-bolt fs-xl opacity-60 me-2' ></i>
                                                        <h6 className="mb-0"><span className={"badge badge-pill " + getBadgeColorByStatus(order?.status)}>{order?.status?.split('_').join(' ')}</span></h6>
                                                    </div>
                                                    <div className="d-flex">
                                                        <i className='bx bxs-calendar-alt fs-xl opacity-60 me-2' ></i>
                                                        <h6 className="mb-1 fs-sm">{date.toLocaleString(DateTime.DATETIME_FULL)}</h6>
                                                    </div>
                                                    <div className="d-flex mb-1">
                                                        <i className="bx bx-key fs-xl opacity-60 me-2" />
                                                        <h6 className="mb-0 fs-sm">{order?.wlTypes?.length}</h6>
                                                    </div>
                                                    <div className="d-flex mb-1">
                                                        <div>
                                                            {
                                                                order?.wlTypes?.includes(WlTypes.FRACTIONALIZATION) &&
                                                                <>
                                                                    <span className={"badge bg-dark me-1"}>COIN SPLIT</span>
                                                                </>
                                                            }
                                                            {
                                                                order?.wlTypes?.filter((f: any) => f !== WlTypes.FRACTIONALIZATION).map((wlType: any) => (<>
                                                                    <span className={"badge me-1 " + (getBadgeColor(wlType))}>{wlType?.split('_').join(' ')}</span>
                                                                </>))
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="d-flex justify-content-end pt-3 pt-sm-0">
                                                <button type="button" disabled={buttonLoading} className="btn btn-outline-secondary px-3 px-xl-4 me-3" onClick={() => onSelectWallet(order?.walletId)}>
                                                    <i className="bx bxs-user-circle fs-xl me-lg-1 me-xl-2" />
                                                    <span className="d-none d-lg-inline">SEE USER</span>
                                                </button>
                                                {
                                                    order.status === MintStatus.WAITING &&
                                                    <button type="button" disabled={buttonLoading} className="btn btn-warning px-3 px-xl-4 me-3" onClick={() => onSelectSend(order?.walletId, index)}>
                                                        {
                                                            !buttonLoading &&
                                                            <>
                                                                <i className="bx bxs-package fs-xl me-lg-1 me-xl-2" />
                                                                <span className="d-none d-lg-inline">QUEUE</span>
                                                            </>
                                                        }
                                                        {
                                                            buttonLoading &&
                                                            <>
                                                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                                <span className="visually-hidden">Loading...</span>
                                                            </>
                                                        }
                                                    </button>
                                                }
                                                {
                                                    order.status === MintStatus.STARTED &&
                                                    <button type="button" disabled={buttonLoading} className="btn btn-primary px-3 px-xl-4 me-3" onClick={() => onSelectDistro(order?.distributionId)}>
                                                        {
                                                            !buttonLoading &&
                                                            <>
                                                                <i className="bx bxs-meteor fs-xl me-lg-1 me-xl-2" />
                                                                <span className="d-none d-lg-inline">CONFIRM</span>
                                                            </>
                                                        }
                                                        {
                                                            buttonLoading &&
                                                            <>
                                                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                                <span className="visually-hidden">Loading...</span>
                                                            </>
                                                        }
                                                    </button>
                                                }
                                                {
                                                    (order.status === MintStatus.PROCESSING || order.status === MintStatus.COMPLETED) &&
                                                    <button type="button" disabled={buttonLoading} className="btn btn-dark px-3 px-xl-4 me-3" onClick={() => onSelectDistro(order?.distributionId)}>

                                                        {
                                                            !buttonLoading &&
                                                            <>
                                                                <i className="bx bx-archive fs-xl me-lg-1 me-xl-2" />
                                                                <span className="d-none d-lg-inline">OPEN</span>
                                                            </>
                                                        }
                                                        {
                                                            buttonLoading &&
                                                            <>
                                                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                                <span className="visually-hidden">Loading...</span>
                                                            </>
                                                        }
                                                    </button>
                                                }
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </>
                    }
                    {/* Item */}
                    {/* <div className="py-2">
                        <button type="button" className="btn btn-primary ps-4">
                            <i className="bx bx-plus fs-xl me-2" />
                            Add new payment method
                        </button>
                    </div> */}
                </div>
            </div>
        </>
    )
}

const WalletOrderCard = ({
    order
}: any) => {
    const date = DateTime.fromISO(order?.createdAt);
    return (
        <div className="card d-sm-flex flex-sm-row align-items-sm-center justify-content-between border-0 shadow-sm p-3 p-md-4 mb-4">
            <div className="d-flex align-items-center pe-sm-3">
                <div className="">
                    <h6 className="mb-1">{date.toLocaleString(DateTime.DATETIME_FULL)}</h6>
                    <h6 className="mb-2"><span className={"badge " + getBadgeColorByStatus(order?.status)}>{order?.status}</span><span className={"badge ms-1 bg-dark"}>{order?.wlType?.split('_').join(' ')}</span></h6>
                </div>
            </div>
            <div className="d-flex justify-content-end pt-3 pt-sm-0">
                {/* <button type="button" className="btn btn-outline-secondary px-3 px-xl-4 me-3">
                    <i className="bx bx-collection fs-xl me-lg-1 me-xl-2" />
                    <span className="d-none d-lg-inline">SEE WALLET'S ORDERS</span>
                </button> */}
            </div>
        </div>
    )
}

const WalletListSection = ({
    orders,
    walletId
}: any) => {
    return (
        <>
            <div className="col-md-8 offset-lg-1 pb-5 mb-2 mb-lg-4 pt-md-5 mt-n3 mt-md-0">
                <div className="ps-md-3 ps-lg-0 mt-md-2 pt-md-4 pb-md-2">
                    <h1 className="h2 pt-xl-1 mb-0">Orders</h1>
                    <p className="mb-3 pb-2 pb-lg-3">{walletId}</p>
                    <h2 className="h5 text-warning d-flex">Waiting
                        <button type="button" disabled={orders?.filter((order: any) => order.status === MintStatus.WAITING)?.length === 0} className="btn btn-primary btn-sm ms-auto">
                            <i className="bx bx-plus fs-xl me-2" />
                            CREATE DISTRIBUTION
                        </button>
                    </h2>
                    {
                        orders?.filter((order: any) => order.status === MintStatus.WAITING)?.map((order: any, index: any) => (
                            <div key={index}>
                                <WalletOrderCard order={order} />
                            </div>
                        ))
                    }
                    <h2 className="h5 text-primary d-flex">Started
                    </h2>
                    {
                        orders?.filter((order: any) => order.status === MintStatus.STARTED)?.map((order: any, index: any) => (
                            <div key={index}>
                                <WalletOrderCard order={order} />
                            </div>
                        ))
                    }
                    <h2 className="h5 text-danger d-flex">Processing
                    </h2>
                    {
                        orders?.filter((order: any) => order.status === MintStatus.PROCESSING)?.map((order: any, index: any) => (
                            <div key={index}>
                                <WalletOrderCard order={order} />
                            </div>
                        ))
                    }
                    <h2 className="h5 text-success d-flex">Completed
                    </h2>
                    {
                        orders?.filter((order: any) => order.status === MintStatus.COMPLETED)?.map((order: any, index: any) => (
                            <div key={index}>
                                <WalletOrderCard order={order} />
                            </div>
                        ))
                    }
                </div>
            </div>
        </>
    )
}

const OrdersContainer = ({
    orders,
    loading,
    buttonLoading,
    selectedStatus,
    onSelectStatus,
    onSelectWallet,
    onSelectSend,
    onSelectDistro,
    onRefresh,
    onOpenFractionalization,
    onOpenMultiAirdrop,
    onMultiQueue,
    onMultiQueueBottom,
    onOpenCoinCat
}: any) => {
    return (
        <>
            <section className="container pt-5">
                <div className="row">
                    {/* Sidebar (User info + Account menu) */}
                    <Sidebar selected={selectedStatus} onSelect={onSelectStatus} />
                    {/* Account collections */}
                    <ListSection
                        loading={loading}
                        buttonLoading={buttonLoading}
                        selectedStatus={selectedStatus}
                        orders={orders}
                        onSelectWallet={onSelectWallet}
                        onSelectSend={onSelectSend}
                        onSelectDistro={onSelectDistro}
                        onRefresh={onRefresh}
                        onOpenFractionalization={onOpenFractionalization}
                        onOpenMultiAirdrop={onOpenMultiAirdrop}
                        onMultiQueue={onMultiQueue}
                        onMultiQueueBottom={onMultiQueueBottom}
                        onOpenCoinCat={onOpenCoinCat} />

                </div>
            </section>

        </>
    )
}

const WalletOrdersContainer = ({
    walletId,
    orders,
    onBackToOrders
}: any) => {
    return (
        <>
            <section className="container pt-5">
                <div className="row">
                    {/* Sidebar (User info + Account menu) */}
                    <WalletSidebar walletId={walletId} onSelect={onBackToOrders} />
                    {/* Account collections */}
                    <WalletListSection orders={orders} walletId={walletId} />
                </div>
            </section>
        </>
    )
}

export const Orders = () => {
    const wallet = useWallet();
    const { style } = useContext(StyleThemeContext);
    const { auth, signIn, signInViaLedger, isExpired } = useContext(AuthorizationContext);
    const { play } = useContext(AudioContext);
    const params = useParams()


    const [loading, setLoading] = useState<any>(false);
    const enableLoading = () => setLoading(true);
    const disableLoading = () => setLoading(false);
    const [buttonLoading, setButtonLoading] = useState<any>(false);
    const enableButtonLoading = () => setButtonLoading(true);
    const disableButtonLoading = () => setButtonLoading(false);

    const [congratsToastShow, setCongratsToastShow] = useState<boolean>(false);

    const [orders, setOrders] = useState<any>();
    const [selectedStatus, setSelectedStatus] = useState<any>(null);
    const [selectedWallet, setSelectedWallet] = useState<any>(null);
    const [walletOrders, setWalletOrders] = useState<any>();
    const [selectedDistributionId, setSelectedDistributionId] = useState<any>(null);

    const [showInfoModal, setShowInfoModal] = useState(false);
    const handleInfoModalOpen = () => setShowInfoModal(true);
    const handleInfoModalClose = () => setShowInfoModal(false);

    const [showAirdropModal, setShowAirdropModal] = useState(false);
    const handleAirdropModalOpen = () => setShowAirdropModal(true);
    const handleAirdropModalClose = () => setShowAirdropModal(false);

    const [showMultiAirdropDistributionModal, setShowMultiAirdropDistributionModal] = useState(false);
    const handleMultiAirdropDistributionModalOpen = () => setShowMultiAirdropDistributionModal(true);
    const handleMultiAirdropDistributionModalClose = () => setShowMultiAirdropDistributionModal(false);

    const [showAirdropDistributionModal, setShowAirdropDistributionModal] = useState(false);
    const handleAirdropDistributionModalOpen = () => setShowAirdropDistributionModal(true);
    const handleAirdropDistributionModalClose = () => setShowAirdropDistributionModal(false);

    const [showFractionalizationModal, setShowFractionalizationModal] = useState(false);
    const handleFractionalizationModalOpen = () => setShowFractionalizationModal(true);
    const handleFractionalizationModalClose = () => setShowFractionalizationModal(false);

    const [showCoinCatModal, setShowCoinCatModal] = useState(false);
    const handleCoinCatModalOpen = () => setShowCoinCatModal(true);
    const handleCoinCatModalClose = () => setShowCoinCatModal(false);

    useEffect(() => {
        if (wallet.connected) {
            handleInfoModalOpen();
        }
    }, [wallet])

    useEffect(() => {
        if (params?.walletId?.length) {
            setSelectedWallet(params?.walletId);
        }
    }, [params])

    useEffect(() => {
        if (wallet.connected) {
            fetchOrders(selectedStatus);
        }
    }, [selectedStatus])

    const fetchOrders = async (status: any) => {
        enableLoading();
        const orders = await getMints(status);

        let mergedOrders;
        if (status === MintStatus.WAITING) {
            mergedOrders = groupMintOrdersByWalletWaiting(orders)?.reverse();
        } else {
            mergedOrders = groupMintOrdersByWalletNotWaiting(orders);
        }
        setOrders(mergedOrders);
        disableLoading();
    }

    const onCloseInfoModal = async () => {
        enableLoading();
        try {
            await signIn(
                wallet?.publicKey?.toString() as string,
                `` as string
            );

        }
        catch {
            disableLoading();
            return;
        }

        disableLoading();
        handleInfoModalClose();

        if (selectedWallet?.length) {
            onSelectWallet(selectedWallet);
        } else {
            setSelectedStatus(MintStatus.WAITING);
        }
    }

    const onLoginViaLedger = async () => {
        enableLoading();
        try {
            await signInViaLedger(wallet);
        }
        catch {
            disableLoading();
            return;
        }

        disableLoading();
        handleInfoModalClose();
    }

    const checkIfTokensAreOkay = async (arr: any[]) => {
        // const nfts = arr.filter(a => !a.burned);
        // enableLoading();
        // const mints = nfts.map((token: any) => token.mint);

        // try {
        //     const tx = await checkForInvalidTokenAccountsAndExecute(wallet, mints);
        //     console.log(tx);
        //     await onBurn(arr);
        // } catch (e:any) {
        //     console.log(e);
        // }

        // disableLoading();
    }

    const selectMenu = (item: any = MintStatus.WAITING) => {
        setSelectedStatus(item);
        return true;
    }

    const onSelectWallet = async (walletId: any) => {
        setSelectedWallet(walletId);
        const orders = await getMintsByWallet(walletId);
        setWalletOrders(orders);
    }


    // const S3_BASE = 'assets/';
    // const init = async () => {
    //     setLoading(true);
    //     const coins = await getBurnedTokens();
    //     const result = coins?.map((coin: any) => {
    //     const fileName = coin?.uri?.split(S3_BASE)[1];
    //     const [tokenIndex] = fileName?.split('.json');
    //     return {
    //         ...coin,
    //         tokenNum: (+tokenIndex) + 1,
    //         tokenIndex,
    //         name: `DCF #${(+tokenIndex) + 1}`
    //     }
    //     })
    //     .sort((a: any, b:any) => a.tokenNum - b.tokenNum)
    //     .filter((coin:any) => coin?.fractionalized?.length && !coin?.ogDropped?.length);
    //     for (let i =0; i<10;i++) {
    //         const coin = result[i];
    //         const res = await createCoinCatMintOrder(coin?.tokenId, auth?.idToken);
    //         console.log('created a mint order');
    //         console.log(res);
    //         await onSelectSend(coin?.walletId);
    //     }
    //     setLoading(false);
    //     disableButtonLoading();
    // }

    const [modalState, setModalState] = useState<any>();
    const onSelectSend = async (walletId: any, index: any = 0) => {
        enableButtonLoading();
        const orders = await getMintsByWallet(walletId);
        const filteredOrders = orders?.filter((order: any) => order.status === MintStatus.WAITING);
        // const filteredOrders = [filteredOrderss[0]];
        // single drop
        if (filteredOrders?.length === 1 && filteredOrders[0].tokenNum <= 555) {
            const order = filteredOrders[0];
            const tokens = await getMintTokens(MintTokenFilterType.ALL, MintTokenFilter.ALL, 2000, filteredOrders[0].tokenNum);
            console.log(order);
            console.log(tokens);
            const request = {
                mintOrders: [order],
                tokens: tokens,
                recipientId: walletId
            };
            const response = await startAirdropDistribution(request, auth?.idToken);
            console.log(response);
            setMultiQueueMsg(response);
            setCongratsToastShow(true);
            // onRefresh(selectedStatus);
            // setModalState({
            //     orders: [order],
            //     tokens: tokens,
            //     walletId,
            //     index
            // });
        }
        // multi drop 
        else {
            const tokens = await getMintTokens(MintTokenFilterType.AVAILABLE, MintTokenFilter.UNRESERVED, filteredOrders?.length);
            console.log(tokens?.slice(0, orders?.length));
            setModalState({
                orders: filteredOrders,
                tokens: tokens?.slice(0, filteredOrders?.length),
                walletId,
                index
            });
            handleAirdropModalOpen();
        }
        disableButtonLoading();
    }

    const onStartedAirdrop = (id: any, index: any) => {
        setSelectedDistributionId(id);
        handleAirdropModalClose();
        // setOrders(orders.filter((order: any) => order.id !== id));
        // console.log(index);
        // handleAirdropDistributionModalOpen();
    }

    const onSelectDistro = (id: any) => {
        setSelectedDistributionId(id);
        handleAirdropDistributionModalOpen();
    }

    const onRefresh = (status: any) => {
        fetchOrders(status);
    }

    const onOpenMultiAirdrop = () => {
        handleMultiAirdropDistributionModalOpen();
    }

    const [multiQueueMsg, setMultiQueueMsg] = useState();
    const [err, setErr] = useState<any>();

    const onMultiQueueBottom = async (orders: any) => {
        enableButtonLoading();
        setErr(null);
        try {
            for (var i = 0; orders[i]; i++) {
                let order = orders[i];
                let walletId = order?.walletId;
                const newOrders = await getMintsByWallet(walletId);
                const filteredOrders = newOrders?.filter((order: any) => order.status === MintStatus.WAITING);
                const tokens = await getMintTokens(MintTokenFilterType.AVAILABLE, MintTokenFilter.UNRESERVED, filteredOrders?.length);
                const newTokens = tokens?.reverse()?.slice(0, filteredOrders?.length);
                const request = {
                    mintOrders: filteredOrders,
                    tokens: newTokens,
                    recipientId: walletId
                };
                const response = await startAirdropDistribution(request, auth?.idToken);
                console.log(response);
                setMultiQueueMsg(response);
                setCongratsToastShow(true);
            }
        } catch (e) {
            console.log(e);
            setErr(e);
            setCongratsToastShow(true);
        }
        disableButtonLoading();
        onRefresh(selectedStatus);
    }

    const onMultiQueue = async (orders: any) => {
        enableButtonLoading();
        setErr(null);
        try {
            for (var i = 0; orders[i]; i++) {
                let order = orders[i];
                let walletId = order?.walletId;
                const newOrders = await getMintsByWallet(walletId);
                const filteredOrders = newOrders?.filter((order: any) => order.status === MintStatus.WAITING);
                const tokens = await getMintTokens(MintTokenFilterType.AVAILABLE, MintTokenFilter.UNRESERVED, filteredOrders?.length);
                const newTokens = tokens?.slice(0, filteredOrders?.length);
                const request = {
                    mintOrders: filteredOrders,
                    tokens: newTokens,
                    recipientId: walletId
                };
                const response = await startAirdropDistribution(request, auth?.idToken);
                console.log(response);
                setMultiQueueMsg(response);
                setCongratsToastShow(true);
            }
        } catch (e) {
            console.log(e);
            setErr(e);
            setCongratsToastShow(true);
        }
        disableButtonLoading();
        onRefresh(selectedStatus);
    }

    return (
        <>
            <ToastCongrats show={congratsToastShow} multiQueueMsg={multiQueueMsg} e={err} onClose={() => setCongratsToastShow(false)}></ToastCongrats>
            {
                showInfoModal &&
                <InfoModal
                    wallet={wallet}
                    show={showInfoModal}
                    isAuth={!!auth}
                    loading={loading}
                    styleCss={style}
                    onLoginViaLedger={onLoginViaLedger}
                    onClose={() => onCloseInfoModal()}
                />
            }
            {
                showAirdropModal &&
                <NewAirdropModal
                    wallet={wallet}
                    state={modalState}
                    styleCss={'light'}
                    show={showAirdropModal}
                    onStarted={(id: any) => { onStartedAirdrop(id, modalState?.index); onRefresh(selectedStatus); }}
                    onClose={() => { handleAirdropModalClose(); onRefresh(selectedStatus) }}
                    onHide={() => { handleAirdropModalClose(); }}
                />
            }
            {
                showAirdropDistributionModal &&
                <AirdropDistributionModal
                    wallet={wallet}
                    distributionId={selectedDistributionId}
                    styleCss={'light'}
                    show={showAirdropDistributionModal}
                    onClose={() => { handleAirdropDistributionModalClose() }}
                    onHide={() => { handleAirdropDistributionModalClose(); }}
                />
            }
            {
                showMultiAirdropDistributionModal &&
                <MultiAirdropDistributionModal
                    wallet={wallet}
                    orders={orders?.slice(0, orders?.length > 10 ? 10 : orders?.length)}
                    styleCss={'light'}
                    show={showMultiAirdropDistributionModal}
                    onClose={() => { handleMultiAirdropDistributionModalClose(); onRefresh(selectedStatus) }}
                    onHide={() => { handleMultiAirdropDistributionModalClose(); }}
                />
            }
            {
                showFractionalizationModal &&
                <NewFractionalizationModal
                    wallet={wallet}
                    styleCss={'light'}
                    show={showFractionalizationModal}
                    onClose={() => { handleFractionalizationModalClose(); onRefresh(selectedStatus) }}
                    onHide={() => { handleFractionalizationModalClose(); }}
                />
            }
            {
                showFractionalizationModal &&
                <NewFractionalizationModal
                    wallet={wallet}
                    styleCss={'light'}
                    show={showFractionalizationModal}
                    onClose={() => { handleFractionalizationModalClose(); onRefresh(selectedStatus) }}
                    onHide={() => { handleFractionalizationModalClose(); }}
                />
            }
            {
                showCoinCatModal &&
                <CoinCatModal
                    wallet={wallet}
                    styleCss={'light'}
                    show={showCoinCatModal}
                    onClose={() => { handleCoinCatModalClose(); onRefresh(selectedStatus) }}
                    onHide={() => { handleCoinCatModalClose(); }}
                />
            }
            {
                !wallet.connected &&
                <section className="h-100vh d-flex">
                    <WalletMultiButton style={{ margin: 'auto' }} />
                </section>
            }
            {
                wallet.connected &&
                <>
                    {
                        !selectedWallet?.length &&
                        <div className="">
                            <OrdersContainer
                                orders={orders}
                                loading={loading}
                                buttonLoading={buttonLoading}
                                selectedStatus={selectedStatus}
                                onSelectStatus={selectMenu}
                                onSelectWallet={onSelectWallet}
                                onSelectSend={onSelectSend}
                                onSelectDistro={onSelectDistro}
                                onRefresh={() => onRefresh(selectedStatus)}
                                onOpenFractionalization={handleFractionalizationModalOpen}
                                onOpenMultiAirdrop={onOpenMultiAirdrop}
                                onMultiQueue={() => onMultiQueue(orders?.slice(0, orders?.length > 100 ? 100 : orders?.length))}
                                onMultiQueueBottom={() => onMultiQueueBottom(orders?.slice(orders?.length > 50 ? orders?.length - 50 : 0, orders?.length))}
                                onOpenCoinCat={handleCoinCatModalOpen}
                            />
                        </div>
                    }
                    {
                        selectedWallet?.length &&
                        <>
                            <WalletOrdersContainer
                                walletId={selectedWallet}
                                orders={walletOrders}
                                onBackToOrders={() => { selectMenu(MintStatus.WAITING); setSelectedWallet(null); }}
                            />
                        </>
                    }
                </>
            }
        </>
    )
}
import { DateTime } from "luxon";
import { useContext, useState } from "react";
import { Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { startAirdropDistribution } from "../../api/distributions.service";
import { getMintTokens } from "../../api/mints.service";
import { AuthorizationContext } from "../../contexts/auth.context";
import { MintTokenFilter, MintTokenFilterType } from "../../utils/constants";
import { LoadingBars } from "../LoadingBars";

export const openInNewTab = (url) => {
  const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
  // console.log(newWindow);
  if (newWindow) newWindow.opener = null
};

const WalletOrderCard = ({
  order,
  token
}) => {
  const date = DateTime.fromISO(order?.createdAt);
  return (
    <div className="card d-sm-flex flex-sm-row align-items-sm-center justify-content-between border-0 shadow-sm p-3 p-md-4 mb-4">
      <div className="d-flex align-items-center pe-sm-3">
        <div className="">
          {
            !token &&
            <h6>___</h6>
          }
          {
            !!token &&
            <>
              <h6 className="mt-1 mb-0">{token?.name}</h6>
              <h6 className="mb-1">
                <a href="javascript:;" onClick={() => openInNewTab(`https://solscan.io/token/${token?.tokenId}`)}>{token?.tokenId}&nbsp;
                  <i className="fas fa-sm fa-external-link-alt cursor-pointer" onClick={() => openInNewTab(`https://solscan.io/token/${token?.tokenId}`)}></i>
                </a>
              </h6>
            </>
          }
          <h6 className="mb-1"><span className={"badge bg-warning"}>{order?.status}</span><span className={"badge ms-1 bg-dark"}>{order?.wlType?.split('_').join(' ')}</span></h6>
          <span className="mb-0 fs-sm">{date.toLocaleString(DateTime.DATETIME_FULL)}</span>
        </div>
      </div>
      <div className="d-flex justify-content-end pt-3 pt-sm-0">
        {/* <button type="button" className="btn btn-outline-secondary px-3 px-xl-4 me-3">
                  <i className="bx bx-collection fs-xl me-lg-1 me-xl-2" />
                  <span className="d-none d-lg-inline">SEE WALLET'S ORDERS</span>
              </button> */}
      </div>
    </div>
  )
}


export function NewAirdropModal({ wallet, state, styleCss, onStarted, ...props }) {
  const navigate = useNavigate();
  const { auth, signNonce } = useContext(AuthorizationContext);
  const [totalSol, setTotalSol] = useState();
  const [loading, setLoading] = useState(false);

  const [tokens, setTokens] = useState();

  const handleInputChange = (event) => {
    console.log(event.target.value);
    setTotalSol(event.target.value);
  }
  const handleSubmit = async (event, mintOrders, tokens, recipientId) => {
    setLoading(true);
    event.preventDefault();
    const request = {
      mintOrders,
      tokens,
      recipientId
    };
    console.log(request);
    // const signature = await signNonce();
    // console.log(signature);
    const signature = null;
    try {
      const response = await startAirdropDistribution(request, auth?.idToken);
      console.log(response);
      onStarted(response?.id);
      setLoading(false);
    }catch (e) {
      setLoading(false);
      console.log(e);
    }
    // console.log(event.target.value);
    // setLoading(true);
    // try {
    //   const signature = await signNonce();
    //   console.log(signature);
    //   const payload = await startDistribution(snapshotId, totalSol, signature, auth?.idToken);

    //   console.log(payload);
    //   navigate(`../distributions/${payload.id}`, { replace: true });
    // }
    // catch (e) {
    //   setLoading(false);
    //   console.log(e);
    // }
  }

  const fetchTokens = async (orders) => {
    setLoading(true);
    // const tokens = await getMintTokens(MintTokenFilterType.AVAILABLE, MintTokenFilter.UNRESERVED, orders?.length);
    console.log(tokens);
    setTokens(tokens);
    setLoading(false);
  }

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      contentClassName={'light' + `-color`}
      centered
    >
      <Modal.Body className={"p-0"}>
        <div className="card card-user shadow-lg">
          <form className="needs-validation mb-2" noValidate onSubmit={(ev) => handleSubmit(ev, state?.orders, state?.tokens, state?.walletId)}>

            <div className={"card-body p-4" + (styleCss === 'dark' ? ' pb-0' : '')} style={{ maxHeight: '690px', overflow: 'scroll' }}>
              <div className="w-100 align-self-end" style={{ maxWidth: '526px' }}>
                <div>
                    <h6 className="mb-0">Queue:</h6>
                    <h5 className="mb-2">{state?.orders?.length} Fat Cats </h5>
                    <h6 className="mb-0">To This Address:</h6>
                    <h5 className="mb-0">{state?.walletId}</h5>
                </div>
                {
                  wallet?.connected &&
                  <>
                    <div className="position-relative mb-4">
                      {
                        state?.orders?.map((order, i) => <WalletOrderCard order={order} key={i} token={state?.tokens?.length > 0 ? state?.tokens[i] : null} />)
                      }
                    </div>
                  </>
                }
              </div>
            </div>
            <div className="card-footer">
              <>
                {
                  !state?.tokens?.length > 0 &&
                  <button type="button" disabled={loading} onClick={() => fetchTokens(state?.orders)} className="btn btn-warning shadow-warning btn-lg w-100">

                    {
                      !loading &&
                      <>
                        <i className="bx bx-sync fs-xl me-lg-1 me-xl-2" />
                        <span className="d-none d-lg-inline">ASSIGN FAT CATS</span>
                      </>
                    }
                    {
                      loading &&
                      <>
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        <span className="visually-hidden">Loading...</span>
                      </>
                    }
                  </button>
                }
                {
                  !!state?.tokens?.length &&
                  <button type="submit" disabled={loading} className="btn btn-primary shadow-primary btn-lg w-100">

                    {
                      !loading &&
                      <>
                        <i className="bx bx-package fs-xl me-lg-1 me-xl-2" />
                        <span className="d-none d-lg-inline">QUEUE AIRDROP</span>
                      </>
                    }
                    {
                      loading &&
                      <>
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        <span className="visually-hidden">Loading...</span>
                      </>
                    }
                  </button>
                }
              </>
            </div>
          </form>
        </div>
      </Modal.Body>
    </Modal>
  );
}

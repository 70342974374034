import { DateTime } from "luxon";
import { useContext, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { getDistribution, getDistributionTransactions, processAirdropDistribution, processDistributionTransaction, startAirdropDistribution } from "../../api/distributions.service";
import { getMintTokens } from "../../api/mints.service";
import { AuthorizationContext } from "../../contexts/auth.context";
import { DistributionStatus, DistributionType, MintStatus, MintTokenFilter, MintTokenFilterType } from "../../utils/constants";
import { LoadingBars } from "../LoadingBars";

const isDev = process.env.REACT_APP_API_URL?.includes('dev');

const goToExplorer = (address) => {
  if (isDev) openInNewTab(`https://explorer.solana.com${address}?cluster=devnet`);
  else openInNewTab(`https://solscan.io${address}`);
}

export const openInNewTab = (url) => {
  const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
  // console.log(newWindow);
  if (newWindow) newWindow.opener = null
};

const getBadgeColorByStatus = (status) => {
  if (status === DistributionStatus.CANCELED) {
    return `bg-warning`;
  }
  if (status === DistributionStatus.STARTED) {
    return `bg-primary`;
  }
  if (status === DistributionStatus.PROCESSING) {
    return `bg-danger`;
  }
  if (status === DistributionStatus.FINALIZED) {
    return `bg-success`;
  }
}

const TransactionCard = ({
  walletId,
  transaction,
  number,
  onRetry
}) => {
  const date = DateTime.fromISO(transaction?.createdAt);
  return (
    <div className="card d-sm-flex flex-sm-row align-items-sm-center justify-content-between border-0 shadow-sm p-3 p-md-4 mb-4">
      <div className="d-flex align-items-center pe-sm-3">
        <div className="">
          <h6 className="mb-1">Transaction #{number}</h6>
          <div className="d-flex">
            <i className="bx bxs-bolt fs-xl opacity-60 me-2" />
            <h6 className="mb-1">{transaction?.transactionId}</h6>
          </div>
          <div className="d-flex">
            <i className="bx bxs-user-circle fs-xl opacity-60 me-2" />
            <h6 className="mb-1"><a href="javascript:;" onClick={() => goToExplorer(`/account/${walletId}`)}>{walletId}</a></h6>
          </div>
          <div className="d-flex">
            <i className='bx bxs-calendar-alt fs-xl opacity-60 me-2' ></i>
            <h6 className="mb-1 fs-sm">{date.toLocaleString(DateTime.DATETIME_FULL)}</h6>
          </div>
          <div className="d-flex">
            <i className='bx bxs-bolt fs-xl opacity-60 me-2' ></i>
            <h6 className="mb-2">
              <span className={"badge " + getBadgeColorByStatus(transaction?.status)}>{transaction?.status?.split('_').join(' ')}</span>&nbsp;
            </h6>
          </div>
          {
            transaction?.instructions?.map((ix) => (
              <>
                <div className="d-flex">
                  <i className="bx fs-xl opacity-60 me-2 bxs-coin cursor-pointer" onClick={() => goToExplorer(`/token/${ix?.tokenId}`)}></i>
                  <a href="javascript:;" onClick={() => goToExplorer(`/token/${ix?.tokenId}`)}>{ix?.memo}
                  </a>
                </div>
              </>))
          }
          <div className="d-flex mt-1">
            <i className='bx bx-link-alt fs-xl opacity-60 me-2'></i>
            {
              !!transaction?.signature?.length &&
              <>
                <a href="javascript:;" onClick={() => goToExplorer(`/tx/${transaction?.signature}`)}>View Transaction</a>
              </>
            }  {
              !transaction?.signature?.length &&
              <>
                ___
              </>
            }
          </div>
          {
            transaction?.status === DistributionStatus.PROCESSING &&
            <>
              <a href="javascript:;" onClick={() => onRetry(transaction?.distributionId, transaction?.transactionId)}>RETRY</a>
            </>
          }
        </div>
      </div>
      <div className="d-flex justify-content-end pt-3 pt-sm-0">
        {/* <button type="button" className="btn btn-outline-secondary px-3 px-xl-4 me-3">
                  <i className="bx bx-collection fs-xl me-lg-1 me-xl-2" />
                  <span className="d-none d-lg-inline">SEE WALLET'S ORDERS</span>
              </button> */}
      </div>
    </div>
  )
}


export function AirdropDistributionModal({ wallet, distributionId, styleCss, ...props }) {
  const navigate = useNavigate();
  const { auth, signNonce } = useContext(AuthorizationContext);
  const [totalSol, setTotalSol] = useState();
  const [loading, setLoading] = useState(false);
  const [loadingTxes, setLoadingTxes] = useState(true);
  const [loadingButton, setLoadingButton] = useState(false);
  const [tokens, setTokens] = useState();

  const [distributionDetails, setDistributionDetails] = useState();
  const [distributionTransactions, setDistributionTransactions] = useState();

  useEffect(() => {
    if (distributionId && distributionId !== distributionDetails?.id) {
      init(distributionId);
    }
  }, [distributionId])

  const init = async (id) => {
    setLoading(true);
    const dist = await getDistribution(id,
      DistributionType.MINTS_AIRDROP);
    setDistributionDetails(dist);
    setLoading(false);
    refreshTransactions(dist);
  }

  const refreshTransactions = async (dist) => {
    setLoading(true);
    const txes = await getDistributionTransactions(dist?.id);
    setDistributionTransactions(txes);
    setLoadingTxes(false);
    setLoading(false);
  }


  const handleSubmit = async (id) => {
    setLoadingButton(true);

    try {
      // const signature = await signNonce();
      // console.log(signature);
      const response = await processAirdropDistribution(id, auth?.idToken);
      console.log(response);
      props.onClose();
    } catch (e) {
      console.log(e);
      setLoadingButton(false);
    }

    setLoadingButton(false);

  }

  const onRetry = async (id, transactionId) => {
    setLoadingButton(true);
    try {
      const response = await processDistributionTransaction(id, transactionId, auth?.idToken);
      console.log(response);
      props.onClose();
    } catch (e) {
      console.log(e);
      setLoadingButton(false);
    }
    setLoadingButton(false);
  }

  const onMultiRetry = async (id, transactions) => {
    console.log(transactions);
    console.log(id);
    setLoadingButton(true);
    const processing = transactions?.filter(x => x.status === DistributionStatus.PROCESSING);
    console.log(processing);
    try {
      for (var i = 0; i < processing?.length; i++) {
        const transaction = processing[i];
        const transactionId = transaction.transactionId;
        const response = await processDistributionTransaction(id, transactionId, auth?.idToken);
        console.log(response);

      }
      props.onClose();
    } catch (e) {
      console.log(e);
      setLoadingButton(false);
    }
    setLoadingButton(false);
  }

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      contentClassName={'light' + `-color`}
      centered
    >
      <Modal.Body className={"p-0"}>
        <div className="card card-user shadow-lg">

          <div className={"card-body p-4" + (styleCss === 'dark' ? ' pb-0' : '')} style={{ maxHeight: '690px', overflow: 'scroll' }}>
            <div className="w-100 align-self-end" style={{ maxWidth: '526px' }}>

              <div>
                <h6 className="mb-0">Confirm Airdrop:</h6>
                <h5 className="mb-2">{distributionDetails?.airdropInstructions?.length} Fat Cats </h5>
                <h6 className="mb-0">To This Address:</h6>
                <h5 className="mb-0">{distributionTransactions?.length > 0 ? distributionTransactions[0].instructions[0].walletId : '___'}</h5>
              </div>
              {/* <h4 className="text-center text-xl-start mb-0">Airdropping Fat Cats ({distributionDetails?.airdropInstructions?.length})</h4> */}
              <div className="text-center fs-sm mb-2"></div>
              {
                wallet?.connected &&
                <>
                  <div className="position-relative mb-4">
                    {
                      distributionTransactions?.map((transaction, i) =>
                        <TransactionCard
                          transaction={transaction}
                          walletId={transaction?.instructions[0].walletId}
                          key={i}
                          number={i + 1}
                          onRetry={onRetry}
                        />
                      )
                    }
                  </div>
                  {
                    loading &&
                    <LoadingBars />
                  }
                </>
              }
            </div>
          </div>
          <div className="card-footer">
            {
              !loading &&
              <>
                {
                  distributionDetails?.status === DistributionStatus.STARTED && (
                    <>
                      <button type="submit" disabled={loadingButton} onClick={() => handleSubmit(distributionDetails?.id)} className="btn btn-danger shadow-danger btn-lg w-100">

                        {
                          !loadingButton &&
                          <>
                            <i className="bx bxs-meteor fs-xl me-lg-1 me-xl-2" />
                            <span className="d-none d-lg-inline">CONFIRM AIRDROP</span>
                          </>
                        }
                        {
                          loadingButton &&
                          <>
                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            <span className="visually-hidden">Loading...</span>
                          </>
                        }
                      </button>
                    </>
                  )
                }
                {
                  distributionDetails?.status !== DistributionStatus.STARTED && distributionDetails?.status !== DistributionStatus.PROCESSING && (
                    <>
                      <button type="submit" onClick={() => props.onHide()} className="btn btn-dark shadow-warning btn-lg w-100">CLOSE</button>
                    </>
                  )
                }
                {
                  distributionDetails?.status == DistributionStatus.PROCESSING && (
                    <>
                      <button type="submit" onClick={() => onMultiRetry(distributionId, distributionTransactions)} className="btn btn-dark shadow-warning btn-lg w-100">MULTI RETRY</button>
                    </>
                  )
                }
              </>
            }
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

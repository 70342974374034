import { Link } from "react-router-dom";

const ToolBar = () => {

  return (
    <div className="social-icons-left">
      <div className="d-flex flex-row flex-sm-column justify-content-start align-items-center h-100">
        <div className="mt-4 d-flex flex-column mx-auto">
          <div className="d-flex flex-row toolbar">
            <Link to="/">
              <img src="https://i.imgur.com/KuLfSLg.png" className="img-fluid" style={{ maxHeight: '24px' }} />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ToolBar;
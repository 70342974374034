import { useState } from "react";
import { Modal } from "react-bootstrap";
import { LoadingBars } from "../LoadingBars";

export function InfoModal({ styleCss, loading, isAuth, onLoginViaLedger, ...props }) {
    const [enabled, setEnabled] = useState(false);
    setTimeout(function () {
        setEnabled(true);
    }, 1000);
    return (
        <Modal
            {...props}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            contentClassName={styleCss + `-color`}
            centered
        >
            <Modal.Body className={"p-0"}>
                <div className="card card-user shadow-lg">
                    {/* <div className={"card-body d-flex justify-content-center p-4" + (styleCss === 'dark' ? ' pb-0' : '')}>
                        <img src="https://i.imgur.com/U7i8Iig.png"></img>
                    </div> */}
                    <div className="card-footer text-center">
                        {
                            !loading &&
                            <>
                                <button
                                    className="btn btn-block w-100 btn-lg btn-primary" disabled={!enabled}
                                    onClick={props.onClose}>
                                    {isAuth ? `CONNECT` : 'CONNECT'}
                                </button>
                                <a href="javascript:;" onClick={onLoginViaLedger}>USE LEDGER</a>
                            </>
                        }
                        {
                            loading &&
                            <div className="my-3">
                                <LoadingBars />
                            </div>
                        }
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
}









import { MintStatus, MintTokenFilter, MintTokenFilterType, SortOrder } from "../utils/constants";
import { get, post } from "../utils/http.util";

const BASE_URL = process.env.REACT_APP_API_URL ?? 'https://dev-api.degencoinflip.com/v2';

export const getMints = async (status = MintStatus.WAITING, sort = SortOrder.DESCENDING) => {
    const url = `${BASE_URL}/mints?status=${status}&sort=${sort}`;
    const response = await get(url);
    return response?.payload;
}

export const getMintsByWallet = async (walletId: string) => {
    const url = `${BASE_URL}/mints?walletId=${walletId}`;
    const response = await get(url);
    return response?.payload;
}

export const getMintTokens = async (type = MintTokenFilterType.AVAILABLE, filter = MintTokenFilter.UNRESERVED, limit: any = null, tokenNum = null) => {
    let url = `${BASE_URL}/mintTokens?type=${type}&filter=${filter}`;
    if (limit) {
        url = url + `&limit=${limit}`
    }
    if (tokenNum) {
        url = url + `&tokenNum=${tokenNum}`
    }
    const response = await get(url);
    return response?.payload;
}

export const getBurnedTokens = async () => {
    const url = `${BASE_URL}/burned-coins`;
    const response = await get(url);
    return response?.payload;
}

export const createCoinMintOrder = async (tokenId: any, Authorization = '') => {
    const url = `${BASE_URL}/mints`;
    const response = await post(url, { tokenId }, { Authorization });
    return response?.payload;
}

export const createCoinCatMintOrder = async (tokenId: any, Authorization = '') => {
    const url = `${BASE_URL}/mints/one`;
    const response = await post(url, { tokenId }, { Authorization });
    return response?.payload;
}
import { useCallback, useState } from "react";
import confetti from "canvas-confetti";
import { useInterval } from "../../hooks/useInterval";

const constants = {
    RED: "#dc3545",
    GREEN: "#28a745"
}

const SocialBar = () => {
    const [isShowSquare, setIsShowSquare] = useState(false);
    const showSquare = () => {
        setIsShowSquare(true);
    }
    const hideSquare = () => {
        setIsShowSquare(false);
    }

    useInterval(async () => {
        const r = Math.random();
        if (r < 0.5) {
            onClick(constants.RED);

        } else {
            onClick(constants.GREEN);
        }
    }, 1200);

    const onClick = useCallback((color) => {
        confetti({
            particleCount: 20,
            spread: 10,
            colors: [color],
            origin: {
                x: 1, y: 1
            },
            angle: 135,
            zIndex: 0
        });
    }, []);

    return (
        <div className="social-icons-bottom-right">
            <div className="d-flex flex-row flex-sm-column justify-content-start align-items-center h-100">
                <div className="d-flex flex-row shortcut-row text-end" onClick={onClick}>
                    <span className={"mt-auto opacity-transition " + (isShowSquare ? "" : "opacity-0")} style={{ fontSize: '.7em' }}>*CONFETTI IS A LIVE PLAY ON DCF.</span>
                    <img src="https://i.imgur.com/3sfOqGo.png" onMouseOver={showSquare} onMouseOut={hideSquare} className="img-fluid" style={{ maxWidth: '320px' }} />
                </div>
            </div>
        </div>
    );
}

export default SocialBar;
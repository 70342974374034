import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { deMemoize } from "../../utils/helpers";

export const openInNewTab = (url) => {
  const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
  if (newWindow) newWindow.opener = null
}

export function MemoModal({ memo, ...props }) {
  const [tokens, setTokens] = useState();
  useEffect(() => {
    if (memo?.length) {
      const { tokens } = deMemoize(memo);
      console.log(tokens);
      setTokens(tokens);
    }
  }, [memo]);
  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      contentClassName={'light' + `-color`}
      centered
    >
      <Modal.Body className={"p-0"}>
        <div className="card card-user shadow-lg">
          <div className={"card-body p-4"} style={{ maxHeight: '484px', overflow: 'scroll' }}>
            <div className="w-100 align-self-end" style={{ maxWidth: '526px' }}>
              <h1 className="text-center text-xl-start">Tokens ({tokens?.length})</h1>
              {
                tokens?.map((token) => (
                  <>
                    <h6 className="mb-1">
                      <a href="javascript:;" onClick={() => openInNewTab(`https://solscan.io/token/${token}`)}>{token}&nbsp;
                    <i className="fas fa-sm fa-external-link-alt cursor-pointer"  onClick={() => openInNewTab(`https://solscan.io/token/${token}`)}></i>
                      </a>
                    </h6>
                  </>
                ))
              }
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

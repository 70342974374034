import { DateTime } from "luxon";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getDistributions } from "../../api/distributions.service";
import { getSnapshotHolders, getSnapshots } from "../../api/snapshots.service";
import { DistributionStatus } from "../../utils/constants";


const impl = {
    compute: (items: any) => {
        const map = items?.reduce((prev: any, next: any) => {
            const {
                walletId
            } = next;
            if (walletId in prev) {
                prev[walletId].amount += 1;
            }
            else {
                prev[walletId] = {
                    ...next,
                    amount: 1,
                };
            }
            return prev;
        }, {});

        const result = Object.keys(map).map(id => map[id]);
        return result;
    }
};


export const Distributions = () => {
    const [currSnapshot, setCurrentSnapshot] = useState<any>();
    const [snapshots, setSnapshots] = useState<any>([]);
    const [holders, setHolders] = useState<any>([]);

    const [distributions, setDistributions] = useState<any>();

    useEffect(() => {
        init();
    }, [])
    useEffect(() => {
        if (currSnapshot) {
            getHolders(currSnapshot);
        }
    }, [currSnapshot])
    const init = async () => {
        const result = await getDistributions();
        const distros = result?.map((distribution: any) => ({
            ...distribution,
            date: DateTime.fromISO(distribution?.createdAt)
        }));
        setDistributions(distros);
    }
    const getHolders = async (snapshot: any) => {
        const holders = await getSnapshotHolders(snapshot.id);
        const mapped = impl.compute(holders);
        console.log(mapped);
        setHolders(mapped.sort((a: any, b: any) => b.amount - a.amount));
    }

    return <div className="container">
        <div className="mt-5 pt-5 mb-1 rounded-3 d-flex">
            <div className="container-fluid pt-5 pb-0">
                <div className="d-sm-flex align-items-center justify-content-between pt-xl-1 mb-3 pb-3">
                    <div>
                        <h1 className="display-5 mb-sm-0">Past Distributions </h1>
                        <Link to={`/holders`} className="text-decoration-none">
                            <span className="fas fa-chevron-left me-2"></span>BACK TO SNAPSHOTS
                        </Link>
                    </div>
                </div>
            </div>
            <img src="https://i.imgur.com/QaWYGKP.gif?ext=gif" className="img-fluid rounded-circle shadow border-3" style={{ maxWidth: '220px' }} />
        </div>
        <div className="ps-md-3 ps-lg-0 mt-md-2 pt-md-4 pb-md-2">
            {
                distributions?.map((distribution: any) => (
                    <Link to={`/distributions/${distribution?.id}`} className="text-decoration-none">
                        <div key={distribution?.id} className="card d-sm-flex flex-sm-row align-items-sm-center justify-content-between border-0 shadow-sm p-3 p-md-4 mb-4 cursor-pointer double-button">
                            <div className="d-flex align-items-center pe-sm-3">
                                <img src="https://i.imgur.com/KDZOSbA.png" width={84} alt="pfp" />
                                <div className="ps-3 ps-sm-4">
                                    <div>
                                        <h6 className="mb-sm-0">Date </h6>
                                        <h5 className="mb-sm-2">{distribution?.date?.toFormat('MMMM dd, yyyy')}</h5>
                                        <h6 className="mb-sm-0">Total Sol</h6>
                                        <h5 className="mb-sm-2">{+(distribution?.totalSol).toFixed(7)} SOL </h5>
                                        <h6 className="mb-sm-0">Escrow</h6>
                                        <h6 className="mb-sm-0">{distribution?.escrow}</h6>
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex justify-content-end pt-3 pt-sm-0">
                                {
                                    distribution?.status === DistributionStatus.STARTED &&
                                    <span className="badge bg-primary ms-1">READY</span>
                                }
                                {
                                    distribution?.status === DistributionStatus.PROCESSING &&
                                    <span className="badge bg-danger ms-1">PROCESSING</span>
                                }
                                {
                                    distribution?.status === DistributionStatus.FINALIZED &&
                                    <span className="badge bg-success ms-1">EXECUTED</span>
                                }
                                {/* {
                                holder?.banned &&
                                <button type="button" className="btn btn-outline-secondary px-3 px-xl-4">
                                    <i className="bx bx-edit fs-xl me-lg-1 me-xl-2" />
                                    <span className="d-none d-lg-inline">UNBAN</span>
                                </button>}
                            {
                                !holder?.banned &&
                                <button type="button" className="btn btn-outline-danger px-3 px-xl-4">
                                    <i className="bx bx-trash-alt fs-xl me-lg-1 me-xl-2" />
                                    <span className="d-none d-lg-inline">BAN</span>
                                </button>

                            } */}
                            </div>
                        </div>
                    </Link>

                ))
            }
            {/* <div className="py-2">
                <button type="button" className="btn btn-primary ps-4">
                    <i className="bx bx-plus fs-xl me-2" />
                    Add new payment method
                </button>
            </div> */}
        </div>

    </div>;
};
import { useWallet } from "@solana/wallet-adapter-react";
import { WalletMultiButton } from "@solana/wallet-adapter-react-ui";
import { DateTime } from "luxon";
import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getSnapshotHolders, getSnapshots } from "../../api/snapshots.service";
import { LoadingBars } from "../../components/LoadingBars";
import { InfoModal } from "../../components/modals/InfoModal";
import { NewDistributionModal } from "../../components/modals/NewDistributionModal";
import { AuthorizationContext } from "../../contexts/auth.context";

const impl = {
    compute: (items: any) => {
        const map = items?.reduce((prev: any, next: any) => {
            const {
                walletId
            } = next;
            if (walletId in prev) {
                prev[walletId].amount += 1;
            }
            else {
                prev[walletId] = {
                    ...next,
                    amount: 1,
                };
            }
            return prev;
        }, {});

        const result = Object.keys(map).map(id => map[id]);
        return result;
    }
};


export const Holders = () => {
    const wallet = useWallet();
    const { auth, signIn, signInViaLedger } = useContext(AuthorizationContext);
    const [currSnapshot, setCurrentSnapshot] = useState<any>();
    const [snapshots, setSnapshots] = useState<any>([]);
    const [holders, setHolders] = useState<any>([]);
    const [loading, setLoading] = useState(false);
    const enableLoading = () => setLoading(true);
    const disableLoading = () => setLoading(false);
    const [showInfoModal, setShowInfoModal] = useState(false);
    const handleInfoModalOpen = () => setShowInfoModal(true);
    const handleInfoModalClose = () => setShowInfoModal(false);
    const [tokensCount, setTokensCount] = useState();
    // useEffect(() => {
    //     init();
    // }, [])
    useEffect(() => {
        if (currSnapshot) {
            getHolders(currSnapshot);
        }
    }, [currSnapshot])
    useEffect(() => {
        if (wallet.connected) {
            handleInfoModalOpen();
        }
    }, [wallet])

    const init = async () => {
        const result = await getSnapshots();
        const snapshots = result?.map((snapshot: any) => ({
            ...snapshot,
            date: DateTime.fromISO(snapshot?.createdAt)
        }));
        setSnapshots(snapshots);
        setCurrentSnapshot(snapshots[0]);
    }
    const getHolders = async (snapshot: any) => {
        setLoading(true);
        const {
            tokensCount,
            holders
        } = await getSnapshotHolders(snapshot.id);
        setTokensCount(tokensCount);
        setHolders(holders);
        setLoading(false);
    }

    const selectSnapshotDate = (event: any) => {
        const selected = snapshots.find((snapshot: any) => snapshot.id === event?.target?.value);
        setCurrentSnapshot(selected);
    }

    const openDistributionsModal = async (walletId: any) => {
        if (!auth) {
            await signIn(wallet?.publicKey?.toString() ?? '', '');
        }
        handleDistributionModalOpen();
    }

    const onCloseInfoModal = async () => {
        enableLoading();
        try {
            await signIn(
                wallet?.publicKey?.toString() as string,
                `` as string
            );

        }
        catch {
            disableLoading();
            return;
        }

        disableLoading();
        handleInfoModalClose();
        init();
    }

    const onCloseInfoModalLedger = async () => {
        enableLoading();
        try {
            await signInViaLedger(wallet);
        }
        catch {
            disableLoading();
            return;
        }

        disableLoading();
        handleInfoModalClose();
        init();
    }

    const [showDistributionModal, setShowDistributionModal] = useState(false);
    const handleDistributionModalOpen = () => setShowDistributionModal(true);
    const handleDistributionModalClose = () => setShowDistributionModal(false);

    return (
        <>
            {
                showInfoModal &&
                <InfoModal
                    wallet={wallet}
                    show={showInfoModal}
                    isAuth={!!auth}
                    loading={loading}
                    styleCss={`light`}
                    onLoginViaLedger={() => onCloseInfoModalLedger()}
                    onClose={() => onCloseInfoModal()}
                />
            }
            {
                !wallet.connected &&
                <section className="h-100vh d-flex">
                    <WalletMultiButton style={{ margin: 'auto' }} />
                </section>
            }
            {
                wallet.connected &&
                <>

                    {
                        loading &&
                        <div className="my-5 py-5">
                            <LoadingBars />
                        </div>
                    }
                    {
                        !loading && <div className="container">
                            {
                                showDistributionModal &&
                                <NewDistributionModal
                                    wallet={wallet}
                                    snapshotId={currSnapshot.id}
                                    styleCss={'light'}
                                    show={showDistributionModal}
                                    onHide={() => handleDistributionModalClose()}
                                />
                            }
                            <div className="mt-5 pt-5 mb-1 rounded-3 d-flex">
                                <img src="https://i.imgur.com/KDZOSbA.png" className="img-fluid rounded-circle border-3 shadow" style={{ maxWidth: '220px' }} />
                                <div className="container-fluid pt-5 pb-0">
                                    <div className="d-sm-flex align-items-center justify-content-between pt-xl-1 mb-3 pb-3">
                                        <div>
                                            <h1 className="display-5 mb-sm-0">Degen Fat Cats </h1>
                                            <Link to={`/distributions`} className="text-decoration-none">
                                                SEE PAST DISTRIBUTIONS<span className="fas fa-chevron-right ms-2"></span>
                                            </Link>
                                        </div>
                                        {
                                            !!snapshots?.length && currSnapshot &&
                                            <select value={currSnapshot.id} onChange={selectSnapshotDate} className="form-select" style={{ maxWidth: '15rem' }}>
                                                {
                                                    snapshots?.map((snapshot: any) => (
                                                        <option key={snapshot.id} value={snapshot.id}>
                                                            {
                                                                snapshot.date.toLocaleString(DateTime.DATETIME_FULL)
                                                            }
                                                        </option>
                                                    ))
                                                }
                                            </select>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="ps-md-3 ps-lg-0 mt-md-2 pt-md-4 pb-md-2">
                                <div className="d-flex align-items-center justify-content-between pt-xl-1 mb-3 pb-3">
                                    <h1 className="h2 mb-0">Showing {holders?.length} Holders and {tokensCount} NFTs</h1>
                                    {
                                        wallet.connected && (tokensCount === 20000 || tokensCount === 2) &&
                                        <button type="button" className="btn btn-primary px-3 px-sm-4" onClick={() => openDistributionsModal(wallet?.publicKey?.toString())}>
                                            <i className="fa-solid fa-paper-plane me-2"></i>
                                            <span className="d-none d-sm-inline">SEND DISTRIBUTIONS</span>
                                        </button>
                                    }
                                    {
                                        !wallet.connected &&
                                        <WalletMultiButton style={{ marginLeft: 'auto', marginRight: '' }} />
                                    }
                                </div>
                                {
                                    holders?.map((holder: any) => (
                                        <div key={holder?.walletId} className="card border-0 shadow-sm p-3 p-md-4 mb-4">
                                            <div className="d-flex">
                                                {/* <img src="https://i.imgur.com/WtWzHUL.png" width={48} alt="pfp" /> */}
                                                <div className="">
                                                    <h6 className="mb-sm-0">Wallet Address</h6>
                                                    <h5> <span className={`${!holder?.signedAgreementIsValid ? 'text-decoration-line-through' : ''}`}>{holder?.walletId}</span>&nbsp;
                                                        {
                                                            holder?.banned &&
                                                            <span className="badge bg-danger ms-1">BANNED</span>
                                                        }&nbsp;
                                                        {
                                                            holder?.burned &&
                                                            <span className="badge bg-dark ms-1">BURNED</span>
                                                        }&nbsp;
                                                        {
                                                            !holder?.signedAgreementIsValid &&
                                                            <span className="badge bg-warning ms-1">UNELLIGIBLE</span>
                                                        }&nbsp;
                                                        {
                                                            holder?.signedAgreementIsValid && !holder?.burned && !holder?.banned &&
                                                            <span className="badge bg-primary ms-1">SIGNED @ <small>{DateTime.fromISO(holder?.signedAgreementAt).toFormat('LLL dd, yyyy')}</small> <i className="fa-solid fa-check"></i></span>
                                                        }
                                                    </h5>
                                                    <h6 className="mb-sm-0">Number of Tokens</h6>
                                                    <h5 className="mb-sm-0">{holder?.amount} </h5>
                                                </div>
                                            </div>
                                            {/* <div className="d-flex">
                                                {
                                                    Array.from({ length: holder?.amount })?.map((_: any, index: number) => (
                                                        <div className={"d-flex align-items-center justify-content-center bg-transparent rounded-circle" + (index ? " ms-n3" : "")}
                                                            style={{ width: '52px', height: '52px' }}>
                                                            <img src={CHALLENGES[0].coinImageUrl} className="rounded-circle" width={48} alt="Avatar"
                                                                onError={(e: any) => {
                                                                    if (e.target.src !== CHALLENGES[0].coinImageUrl) { e.target.onerror = null; e.target.src = CHALLENGES[0].coinImageUrl; }
                                                                }} />
                                                        </div>
                                                    ))
                                                }
                                            </div> */}
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    }
                </>
            }
        </>
    );
};
import { DateTime } from "luxon";
import { useContext, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { getDistribution, getDistributionTransactions, processAirdropDistribution, startAirdropDistribution } from "../../api/distributions.service";
import { getMintTokens } from "../../api/mints.service";
import { AuthorizationContext } from "../../contexts/auth.context";
import { DistributionStatus, DistributionType, MintStatus, MintTokenFilter, MintTokenFilterType } from "../../utils/constants";
import { LoadingBars } from "../LoadingBars";

const isDev = process.env.REACT_APP_API_URL?.includes('dev');

const goToExplorer = (address) => {
  if (isDev) openInNewTab(`https://explorer.solana.com${address}?cluster=devnet`);
  else openInNewTab(`https://solscan.io${address}`);
}

export const openInNewTab = (url) => {
  const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
  // console.log(newWindow);
  if (newWindow) newWindow.opener = null
};

const getBadgeColorByStatus = (status) => {
  if (status === DistributionStatus.CANCELED) {
    return `bg-warning`;
  }
  if (status === DistributionStatus.STARTED) {
    return `bg-primary`;
  }
  if (status === DistributionStatus.PROCESSING) {
    return `bg-danger`;
  }
  if (status === DistributionStatus.FINALIZED) {
    return `bg-success`;
  }
}

const TransactionCard = ({
  order,
  number
}) => {
  const date = DateTime.fromISO(order?.createdAt);
  return (
    <div className="card d-sm-flex flex-sm-row align-items-sm-center justify-content-between border-0 shadow-sm p-3 p-md-4 mb-4">
      <div className="d-flex align-items-center pe-sm-3">
        <div className="">
          <h6 className="mb-1">{order?.wlTypes?.length} Cats</h6>
          {
            order?.wlTypes?.length > 0 &&
            <div className="d-flex">
              <i className="bx bxs-user-circle fs-xl opacity-60 me-2" />
              <h6 className="mb-1"><a href="javascript:;" onClick={() => goToExplorer(`/account/${order?.walletId}`)}>{order?.walletId}</a></h6>
            </div>
          }
          <div className="d-flex">
            <i className='bx bxs-calendar-alt fs-xl opacity-60 me-2' ></i>
            <h6 className="mb-1 fs-sm">{date.toLocaleString(DateTime.DATETIME_FULL)}</h6>
          </div>
          <div className="d-flex">
            <i className='bx bxs-bolt fs-xl opacity-60 me-2' ></i>
            <h6 className="mb-2">
              <span className={"badge " + getBadgeColorByStatus(order?.status)}>{order?.status?.split('_').join(' ')}</span>&nbsp;
            </h6>
          </div>
          {/* {
            order?.airdropInstructions?.map((ix) => (
              <>
                <div className="d-flex">
                  <i className="bx fs-xl opacity-60 me-2 bxs-coin cursor-pointer" onClick={() => goToExplorer(`/token/${ix?.tokenId}`)}></i>
                  <a href="javascript:;" onClick={() => goToExplorer(`/token/${ix?.tokenId}`)}>DFC #{ix?.tokenNum}
                  </a>
                </div>
              </>))
          }
          <div className="d-flex mt-1">
            <i className='bx bx-link-alt fs-xl opacity-60 me-2'></i>
          </div> */}
        </div>
      </div>
      <div className="d-flex justify-content-end pt-3 pt-sm-0">
        {/* <button type="button" className="btn btn-outline-secondary px-3 px-xl-4 me-3">
                  <i className="bx bx-collection fs-xl me-lg-1 me-xl-2" />
                  <span className="d-none d-lg-inline">SEE WALLET'S ORDERS</span>
              </button> */}
      </div>
    </div>
  )
}


export function MultiAirdropDistributionModal({ wallet, orders, styleCss, ...props }) {
  const navigate = useNavigate();
  const { auth, signNonce } = useContext(AuthorizationContext);
  const [totalSol, setTotalSol] = useState();
  const [loading, setLoading] = useState(false);
  const [loadingTxes, setLoadingTxes] = useState(true);
  const [loadingButton, setLoadingButton] = useState(false);
  const [tokens, setTokens] = useState();

  const [distributionDetails, setDistributionDetails] = useState();
  const [distributionTransactions, setDistributionTransactions] = useState();

  const [distributionOrders, setDistributionOrders] = useState(null);
  const [succeeded, setSucceeded] = useState(null);
  const [failed, setFailed] = useState(null);

  const [showResults, setShowResults] = useState(false);

  useEffect(() => {
    if (orders !== distributionOrders) {
      init(orders);
    }
  }, [orders])

  const init = async (orders) => {
    console.log(orders);
    setDistributionOrders(orders);
    // setLoading(true);
    // const dist = await getDistribution(id,
    //   DistributionType.MINTS_AIRDROP);
    // setDistributionDetails(dist);
    // setLoading(false);
    // refreshTransactions(dist);
  }

  const refreshTransactions = async (dist) => {
    setLoading(true);
    const txes = await getDistributionTransactions(dist?.id);
    setDistributionTransactions(txes);
    setLoadingTxes(false);
    setLoading(false);
  }


  const handleSubmit = async (orders) => {
    setLoadingButton(true);

    let succeeded = [];
    let failed = [];

    for (var i = 0; i < orders?.length; i++) {
      let order = orders[i];
      try {
        const response = await processAirdropDistribution(order?.distributionId, auth?.idToken);
        succeeded.push(response);
      } catch (e) {
        failed.push(order);
        console.log(e);
      }
    }

    setSucceeded(succeeded);
    setFailed(failed);
    setShowResults(true);
    setLoadingButton(false);
  }

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      contentClassName={'light' + `-color`}
      centered
    >
      <Modal.Body className={"p-0"}>
        <div className="card card-user shadow-lg">

          {
            showResults &&
            <>
              <div className={"card-body p-4" + (styleCss === 'dark' ? ' pb-0' : '')} style={{ maxHeight: '690px', overflow: 'scroll' }}>
                <div className="w-100 align-self-end" style={{ maxWidth: '526px' }}>
                  <div>
                    <h6 className="mb-0 text-success">Successfully Sent:</h6>
                    {
                      succeeded?.map(s => (<>
                      { s?.airdropInstructions?.length > 0 && <h5 className="mb-0">{s?.airdropInstructions[0]?.walletId}</h5> }
                      { s?.airdropInstructions?.length === 0 && <h5 className="mb-0">{s?.id}</h5> }
                      </>))
                    }
                    {
                      succeeded?.length === 0 &&
                      <h5 className="mb-0">NONE</h5>
                    }

                    <h6 className="mt-2 mb-0 text-danger">Failed:</h6>
                    {
                      failed?.map(s => (<>
                        <h5 className="mb-0">{s.walletId}</h5>
                      </>))
                    }
                    {
                      failed?.length === 0 &&
                      <h5 className="mb-0">NONE</h5>
                    }
                  </div>
                </div>
              </div>
              <div className="card-footer">
                <>
                  <button type="submit" onClick={() => props.onClose()} className="btn btn-dark shadow-warning btn-lg w-100">CLOSE</button>
                </>
              </div>

            </>
          }

          {
            !showResults &&
            <>
              <div className={"card-body p-4" + (styleCss === 'dark' ? ' pb-0' : '')} style={{ maxHeight: '690px', overflow: 'scroll' }}>
                <div className="w-100 align-self-end" style={{ maxWidth: '526px' }}>

                  <div>
                    <h6 className="mb-0">Confirm Airdrop:</h6>
                    <h5 className="mb-2">{orders?.length} Orders </h5>
                    <h6 className="mb-0">To:</h6>
                    <h5 className="mb-0">{orders?.length} Recipients</h5>
                  </div>
                  {/* <h4 className="text-center text-xl-start mb-0">Airdropping Fat Cats ({distributionDetails?.airdropInstructions?.length})</h4> */}
                  <div className="text-center fs-sm mb-2"></div>
                  {
                    wallet?.connected &&
                    <>
                      <div className="position-relative mb-4">
                        {
                          distributionOrders?.map((order, i) => <TransactionCard order={order} key={i} number={i + 1} />)
                        }
                      </div>
                      {
                        loading &&
                        <LoadingBars />
                      }
                    </>
                  }
                </div>
              </div>
              <div className="card-footer">
                {
                  !loading &&
                  <>
                    <>
                      <button type="submit" disabled={loadingButton} onClick={() => handleSubmit(distributionOrders)} className="btn btn-danger shadow-danger btn-lg w-100">

                        {
                          !loadingButton &&
                          <>
                            <i className="bx bxs-meteor fs-xl me-lg-1 me-xl-2" />
                            <span className="d-none d-lg-inline">CONFIRM AIRDROP</span>
                          </>
                        }
                        {
                          loadingButton &&
                          <>
                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            <span className="visually-hidden">Loading...</span>
                          </>
                        }
                      </button>
                    </>
                  </>
                }
              </div>
            </>
          }

        </div>
      </Modal.Body>
    </Modal>
  );
}

import { useContext, useState } from "react";
import { Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { startDistribution } from "../../api/distributions.service";
import { AuthorizationContext } from "../../contexts/auth.context";
import { LoadingBars } from "../LoadingBars";

const escrowSelections = ["DCF", "DCD", "DCF23", "DCD23"];

export const openInNewTab = (url) => {
  const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
  if (newWindow) newWindow.opener = null
};

export function NewDistributionModal({ wallet, snapshotId, styleCss, ...props }) {
  const navigate = useNavigate();
  const { auth, signNonce } = useContext(AuthorizationContext);
  const [totalSol, setTotalSol] = useState();
  const [loading, setLoading] = useState(false);
  const [currEscrow, setCurrentEscrow] = useState(escrowSelections[0]);

  const handleInputChange = (event) => {
    console.log(event.target.value);
    setTotalSol(event.target.value);
  }
  const handleSubmit = async (event) => {
    console.log(event.target.value);
    event.preventDefault();
    setLoading(true);
    try {
      // const signature = await signNonce();
      // console.log(signature);
      const payload = await startDistribution(snapshotId, totalSol, currEscrow, auth?.idToken);

      console.log(payload);
      navigate(`../distributions/${payload.id}`, { replace: true });
    }
    catch (e) {
      setLoading(false);
      console.log(e);
    }
  }

  const selectEscrow = (event) => {
    setCurrentEscrow(event?.target?.value);
  }

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      contentClassName={'light' + `-color`}
      centered
    >
      <Modal.Body className={"p-0"}>
        <div className="card card-user shadow-lg">
          <div className={"card-body p-4" + (styleCss === 'dark' ? ' pb-0' : '')} style={{ maxHeight: '484px', overflow: 'scroll' }}>
            <div className="w-100 align-self-end" style={{ maxWidth: '526px' }}>
              <h1 className="text-center text-xl-start">Start a Distribution</h1>
              {
                wallet?.connected &&
                <form className="needs-validation mb-2" noValidate onSubmit={handleSubmit}>
                  <div className="position-relative mb-4">
                    <label htmlFor="number" className="form-label fs-base">Total Sol to Distribute</label>
                    <input type="number" id="number" className="form-control form-control-lg" disabled={loading}
                      value={totalSol}
                      onChange={handleInputChange} required />
                    <div className="invalid-feedback position-absolute start-0 top-100">Please enter a valid number!</div>
                  </div>
                  <div className="position-relative mb-4">
                    <label htmlFor="number" className="form-label fs-base">Escrow Source</label>
                    <select value={currEscrow} onChange={selectEscrow} className="form-select" style={{ maxWidth: '15rem' }}>
                      {
                        escrowSelections?.map((escrow) => (<option key={escrow} value={escrow}>{escrow}</option>))
                      }
                    </select>
                  </div>
                  {
                    !loading &&
                    <button type="submit" className="btn btn-primary shadow-primary btn-lg w-100">NEXT</button>
                  }
                  {
                    loading &&
                    <LoadingBars />
                  }
                </form>
              }
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}




import { DistributionType } from "../utils/constants";
import { get, post } from "../utils/http.util";

const BASE_URL = process.env.REACT_APP_FULLSEND_API_URL ?? 'https://dev-api.degenfullsend.com/v1';

export const startDistribution = async (snapshotId = '', totalSol = 0, escrow = '', Authorization = '') => {
  const url = `${BASE_URL}/distributions`
  const response = await post(url, { snapshotId, totalSol, escrow }, { Authorization });
  return response?.data?.payload;
}

export const processDistribution = async (id = '', Authorization = '') => {
  const url = `${BASE_URL}/distributions/${id}`
  const response = await post(url, { }, { Authorization });
  return response?.data?.payload;
}

export const getDistributions = async (community = 'dfc') => {
  const url = `${BASE_URL}/distributions?community=${community}`;
  const response = await get(url);
  return response?.payload;
}

export const getDistribution = async (id: any, type = DistributionType.HOLDERS_SNAPSHOT) => {
  const url = `${BASE_URL}/distributions/${id}?type=${type}`;
  const response = await get(url);
  return response?.payload;
}

export const getDistributionHolders = async (id: any) => {
  const url = `${BASE_URL}/distributions/${id}/holders`;
  const response = await get(url);
  return response?.payload;
}

export const getDistributionTransactions = async (id: any) => {
  const url = `${BASE_URL}/distributions/${id}/transactions`;
  const response = await get(url);
  return response?.payload;
}

export const processDistributionTransaction = async (id = '', transactionId = '', Authorization = '') => {
  const url = `${BASE_URL}/distributions/${id}/transactions/${transactionId}`
  const response = await post(url, {}, { Authorization });
  return response?.data?.payload;
}

export const startAirdropDistribution = async ({ mintOrders, tokens, recipientId }: any, Authorization = '') => {
  const url = `${BASE_URL}/distributions/airdrop`
  const response = await post(url, { mintOrders, tokens, recipientId }, { Authorization });
  return response?.data?.payload;
}

export const processAirdropDistribution = async (id: any, Authorization = '') => {
  const url = `${BASE_URL}/distributions/${id}/airdrop`
  const response = await post(url, {}, { Authorization });
  return response?.data?.payload;
}

import { DateTime } from "luxon";

export const rando = (len = 7) => (Math.random() + 1).toString(36).substring(len);

export const randoSig = () => [...Array(8)].reduce(function (pV) {
  if (!pV?.length) return rando(2);
  return pV?.concat(rando(2));
}, []);

export const memoize = (id, amount, side) => {
  let memo = `id=${id} amount=${amount} side=${side}`;
  return Buffer.from(memo, "utf-8");
};
export const getSideName = (side) => {
  if (side === "H") return "Heads";
  return "Tails";
}

export const deMemoize = (memo) => {
  const separated = memo?.split(" ");
  var dataBack = {};
  separated.forEach(o => {
    let [key, val] = o.split("=");
    dataBack[key] = val;
  });

  // todo real validation elsewhere
  const {
    id,
    amount,
    side,
    result,
    tokens
  } = dataBack;
  return {
    id,
    amount,
    side,
    result,
    tokens: tokens?.split(",")
  };
};
export const groupMintOrdersByWalletWaiting = (items) => {
  const map = items?.reduce((prev, next) => {
    const {
      walletId,
      createdAt,
      id,
      status,
      wlType,
    } = next;
    const dt = DateTime.fromISO(createdAt);
    if (walletId in prev) {
      prev[walletId].ids.push(id);
      prev[walletId].wlTypes.push(wlType);
      prev[walletId].createdAt = dt > prev[walletId].createdAt ? dt : prev[walletId].createdAt
    }
    else {
      prev[walletId] = {
        walletId,
        ids: [id],
        status,
        wlTypes: [wlType],
        createdAt: dt
      };
    }
    return prev;
  }, {});

  const result = Object.keys(map).map(id => map[id]);
  return result;
};


export const groupMintOrdersByWalletNotWaiting = (items) => {
  const map = items?.reduce((prev, next) => {
    const {
      walletId,
      createdAt,
      id,
      status,
      wlType,
      distributionId,
      nickname
    } = next;
    const dt = DateTime.fromISO(createdAt);
    if (distributionId in prev) {
      prev[distributionId].ids.push(id);
      prev[distributionId].wlTypes.push(wlType);
      prev[distributionId].createdAt = dt > prev[distributionId].createdAt ? dt : prev[distributionId].createdAt;
      prev[distributionId].nickname = nickname ?? prev[distributionId].nickname;
    }
    else {
      prev[distributionId] = {
        walletId,
        ids: [id],
        status,
        wlTypes: [wlType],
        createdAt: dt,
        distributionId,
        nickname
      };
    }
    return prev;
  }, {});

  const result = Object.keys(map).map(id => map[id]);
  return result;
};